import React from "react";
// import { Link }         from "gatsby";
import styled from "styled-components";
import Seo from "../../components/seo";
// import Image            from "../../components/image";
import { Header, SubHeader, Body } from "../../components/Typography";
import {
  SiteContent,
  HalfWidth,
  ThreeQuarterWidth,
  QuarterWidth,
} from "../../components/Layouts";
import { Colors } from "../../theme";

// Images
import OrganicCircles from "../../images/svgs/thumbsupstudio_organicCircles.svg";
import LaunchNYBG from "../../images/work/thumbsupstudio-launchny-hero.jpg";

import WebHomeNew from "../../images/work/launchny/thumbsupstudio-launchny-web-home-new.jpg";
import WebHomeOld from "../../images/work/launchny/thumbsupstudio-launchny-web-home-old.jpg";
import WebPortNew from "../../images/work/launchny/thumbsupstudio-launchny-web-portfolio-new.jpg";
import WebPortOld from "../../images/work/launchny/thumbsupstudio-launchny-web-portfolio-old.jpg";

import Outline1 from "../../images/work/launchny/thumbsupstudio_lny-outline-1.svg";
import Outline2 from "../../images/work/launchny/thumbsupstudio_lny-outline-2.svg";
import Outline3 from "../../images/work/launchny/thumbsupstudio_lny-outline-3.svg";
import Outline4 from "../../images/work/launchny/thumbsupstudio_lny-outline-4.svg";

import "../../theme/css/animations.css";

const AboutImageWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: black;
  overflow: hidden;
  min-height: 1200px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: ${Colors.Black};
  background-image: url(${LaunchNYBG});
`;

const BackgroundAnimation = styled.div`
  position: absolute;
  top: -940px;
  width: 100%;
  display: flex;
  min-height: 1600px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(${OrganicCircles});
  animation: subPagescale 6s ease-in-out infinite;
`;

const HeroWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  min-height: 700px;

  @media only screen and (max-width: 780px) {
    min-height: 500px;
  }
`;

const OverviewWrapper = styled.div`
  width: 100%;
  display: inline-block;
  min-height: 420px;
  padding-bottom: 36px;

  @media only screen and (max-width: 780px) {
    min-height: 520px;
  }
`;

const ComparisonWrapper = styled.div`
  padding-bottom: 130px;

  @media only screen and (max-width: 780px) {
    padding-bottom: 130px;
  }
`;

const ComparisonSectionWrapper = styled.div`
  min-height: 270px;
  display: inline-block;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 36px;

  @media only screen and (max-width: 780px) {
    min-height: 370px;
  }
`;

const Title = styled.p`
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  color: ${({ white }) => (white ? Colors.White : Colors.Black)};
  padding-bottom: 9px;
  display: flex;
  line-height: 35px;
`;

const BeforeAfter = styled.h5`
  margin-bottom: 32px;
  margin-top: 18px;
  opacity: 0.4;
  color: ${Colors.White};
  font-size: 24px;
  font-weight: 400;
  width: 100%;
  letter-spacing: 4px;

  @media only screen and (max-width: 780px) {
    text-align: center;
    font-size: 21px;
    margin-bottom: 18px;
    margin-top: 42px;
  }
`;

const ArchitectureWrapper = styled.div`
  height: 100%;
  display: flex;
  min-height: 800px;
  padding-bottom: 150px;

  @media only screen and (max-width: 780px) {
    padding-bottom: 250px;
  }
`;

const ArchCopyWrapper = styled.div`
  display: inline-block;
  padding-top: 32px;

  @media only screen and (max-width: 780px) {
    display: none;
  }
`;

const ArchCopyWrapperMobile = styled.div`
  display: none;

  @media only screen and (max-width: 780px) {
    display: inline-block;
  }
`;

const SVGWrapper1 = styled.img`
  width: 100%;
`;

const SVGWrapper2 = styled.img`
  max-width: 266px;
  padding-left: ${({ first }) => (first ? 0 : 16)}px;
  width: 33%;

  @media only screen and (max-width: 780px) {
    padding-left: 0px;
    padding-bottom: 8px;
    width: 100%;
  }
`;

const LongImage = styled.img`
  border-radius: ${({ small }) => (small ? 4 : 8)}px;
  box-shadow: 0px 20px 62px rgba(55, 158, 231, 0.21);
  width: 80%;

  @media only screen and (max-width: 780px) {
    border-radius: 4px;
    width: 100%;
  }
`;

class BakUSAPage extends React.Component {

  render() {
    return (
      <AboutImageWrapper>
        <Seo title="Thumbs Up Studio Portfolio - Launch NY Web Design & Development" />
        <BackgroundAnimation></BackgroundAnimation>
        <HeroWrapper>
          <SiteContent center>
            <SubHeader large color={Colors.White}>
              Project
            </SubHeader>
            <Header h1 color={Colors.White}>
              Launch NY
            </Header>
          </SiteContent>
        </HeroWrapper>

        <OverviewWrapper
        >
          <SiteContent>
            <ThreeQuarterWidth>
              <Title white>Project Overview</Title>
              <Body color={Colors.White}>
                Launch NY is a nonprofit venture development organization in
                Upstate New York. When the company’s business leaders reached
                out to us for a new website, they weren’t exactly sure what they
                were going to get, but they knew they needed something better,
                and that’s just what we gave them. We agreed that the Launch NY
                website would get a whole-new look: something modern, something
                fresh. So we rehauled it almost entirely—from the architecture,
                to the copy, to the design and development.
                <br />
                <br />
                We completed a brand-new information architecture while
                enhancing the aesthetics, tone, and mood of the website. With
                subsequent phases of the project currently in the works, we are
                continuing to help enhance the site and engage the Launch NY
                audience.
              </Body>
            </ThreeQuarterWidth>
          </SiteContent>
        </OverviewWrapper>

        <ArchitectureWrapper>
          <SiteContent
            flexStart
            wrap
          >
            <QuarterWidth>
              <BeforeAfter h2 color={"rgba(255,255,255, .3)"}>
                Before
              </BeforeAfter>
              <SVGWrapper1 src={Outline1} alt="" />
            </QuarterWidth>
            <ThreeQuarterWidth style={{ paddingLeft: "16px" }} mobileSplit row>
              <BeforeAfter h2 color={"rgba(255,255,255, .3)"}>
                After
              </BeforeAfter>
              <SVGWrapper2 first src={Outline2} alt="" />
              <SVGWrapper2 src={Outline3} alt="" />
              <SVGWrapper2 src={Outline4} alt="" />
              <ArchCopyWrapper
              >
                <Title white>Re-architecture</Title>
                <Body color={Colors.White}>
                  We transformed a single, 15-section page into three distinct
                  pages, each with five Sections. By redistributing,
                  redesigning, and rewriting the website, we created three
                  different opportunities to speak to Launch NY’s three
                  different audiences, giving each customer a clear-cut purpose
                  and call to action.
                </Body>
              </ArchCopyWrapper>
            </ThreeQuarterWidth>
            <ArchCopyWrapperMobile
            >
              <Title white>Re-architecture</Title>
              <Body color={Colors.White}>
                What had been a single page with 15 Sections, turned into 3
                pages with 5 Sections. Breaking up the content like this allows
                us to be as specific as possible to one product or purpose. Each
                page has at least one call-to-action to related content.
              </Body>
            </ArchCopyWrapperMobile>
          </SiteContent>
        </ArchitectureWrapper>

        <ComparisonWrapper>
          <ComparisonSectionWrapper
          >
            <SiteContent wrap>
              <HalfWidth mobileSplit firstChild>
                <BeforeAfter h2 color={"rgba(255,255,255, .3)"}>
                  Before
                </BeforeAfter>
                <LongImage
                  small
                  src={WebHomeOld}
                  alt="Launch NY Home Page Design"
                />
              </HalfWidth>
              <HalfWidth mobileSplit>
                <BeforeAfter h2 color={Colors.White}>
                  After
                </BeforeAfter>
                <LongImage
                  small
                  src={WebHomeNew}
                  alt="Launch NY Home Page Design"
                />
              </HalfWidth>
            </SiteContent>
          </ComparisonSectionWrapper>

          <ComparisonSectionWrapper
          >
            <SiteContent wrap flexStart>
              <HalfWidth mobileSplit firstChild>
                <BeforeAfter h2 color={Colors.White}>
                  Before
                </BeforeAfter>
                <LongImage
                  small
                  src={WebPortOld}
                  alt="Bak USA Home Page Design"
                />
              </HalfWidth>
              <HalfWidth mobileSplit>
                <BeforeAfter h2 color={Colors.White}>
                  After
                </BeforeAfter>
                <LongImage
                  small
                  src={WebPortNew}
                  alt="Bak USA Home Page Design"
                />
              </HalfWidth>
            </SiteContent>
          </ComparisonSectionWrapper>
        </ComparisonWrapper>
      </AboutImageWrapper>
    );
  }
}

export default BakUSAPage;
